import Axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { createDecipheriv } from 'crypto'
import { spacing as spacingValue } from 'constants/css'
import { ICalcSimulate, IEntity, IFactor, IReceivable } from 'interfaces'
import breakpoints from 'constants/breakpoints'
import IAddress from 'interfaces/address'
import { IErrorsResponse } from 'interfaces'
import IInsurer, { InsurerType } from 'interfaces/insurer'
import {
  cnpjNormalizer,
  cnpjValidator,
  cpfNormalizer,
  cpfValidator,
  maxLength,
  phoneNormalizer,
} from 'components/Forms'
import { regexCellPhoneCompany, regexCellPhoneUser } from 'constants/regex'
import { banks } from 'constants/general'

export const capitalize = (value: String | string | undefined): string => {
  if (!value) {
    return ''
  }
  return String(value)?.charAt(0)?.toUpperCase() + String(value)?.slice(1)
}

export const spacing = (size: number): string => {
  return `${size * spacingValue}px`
}

export const breakpoint = (size: 'sm' | 'md' | 'lg'): string => {
  return `${breakpoints[size]}px`
}

export const getFirstLetters = (word: string): String => {
  return (word || '')?.charAt(0)?.toUpperCase()
}

export const formatCurrency = (
  value: Number,
  options?: { style?: 'decimal' | 'currency' }
): string => {
  if (value === undefined) {
    return Number(0).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: options?.style || 'currency',
    })
  }

  return Number(value).toLocaleString('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: options?.style || 'currency',
  })
}

export const formatNumberString = (value: string) => {
  // Remove todos os caracteres que não são números ou ponto
  let formattedValue = value.replace(/[^0-9.]/g, '')

  // Verifica se há mais de um ponto e remove os extras
  const parts = formattedValue.split('.')
  if (parts.length > 2) {
    formattedValue = `${parts[0]}.${parts[1]}`
  }

  // Limita a parte decimal a no máximo duas casas
  if (formattedValue.includes('.')) {
    const [integerPart, decimalPart] = formattedValue.split('.')
    formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`
  }

  return formattedValue
}

export const formatDate = (date: string, format?: string, convertFormat?: string): string => {
  if (date) {
    const momentDate = moment(date, convertFormat)
    return momentDate.format(format || 'DD/MM/YYYY')
  }
  return '-'
}

export const formatPercent = (value: Number | String, abs: boolean = true): string => {
  if (abs && value) {
    return (Number(value) / 100).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  if (value) {
    return Number(value).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  return '0,00%'
}

export const parseCurrency = (value = 0) => {
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }
  if (typeof value === 'string' && !isNaN(parseFloat(value))) {
    return parseFloat(value).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }

  return 'R$ 0,00'
}

export const formatNumberToTwoDecimals = (value: number): number => {
  if (typeof value === 'string') {
    return parseFloat(Number(value).toFixed(2))
  }
  return parseFloat(value.toFixed(2))
}

export const formatPhoneNumber = (str: string) => {
  if (str) {
    const cleaned = str.replace(/(\+\d{2})/g, '')

    if (!cleaned) {
      return ''
    }

    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }

    if (!match && cleaned.length < 11) {
      return cleaned
    }
  }

  return 'Não informado'
}

export const removeNullKeys = (obj: any): Object => {
  return Object.keys(obj).reduce((result, key) => {
    const objValue = obj[key]
    if (objValue === null) {
      return result
    }
    if (objValue instanceof Object) {
      const keyValues = removeNullKeys(objValue)
      return {
        ...result,
        [key]: keyValues,
      }
    }
    return {
      ...result,
      [key]: objValue,
    }
  }, {})
}

export const getPasswordScore = (passwordScore: number) => {
  const scores = [
    [0, 'secondary', 'Senha inválida'],
    [25, 'secondary', 'Senha não permitida'],
    [50, 'primary', 'Senha média'],
    [75, 'primary', 'Senha boa'],
    [100, 'primary', 'Senha ótima'],
  ]
  return scores[passwordScore]
}

export const getCepInfos = (cep: string, change: (name: string, value?: any) => void) => {
  if (typeof cep === 'string' && cep.length === 9) {
    Axios({
      url: `${process.env.REACT_APP_CREDITOR_BASE_URL}/v3/correios/cep/consultar/${cep.replace(
        /[^0-9]/,
        ''
      )}`,
      method: 'GET',
    }).then((address: AxiosResponse<IAddress>) => {
      const { logradouro, complemento, bairro, localidade, uf } = address.data
      change('endereco.cidade', localidade)
      change('endereco.bairro', bairro)
      change('endereco.complemento', complemento)
      change('endereco.uf', uf)
      change('endereco.logradouro', logradouro)
    })
  }
}

export const formatErrors = (errors: IErrorsResponse): string | undefined => {
  if (errors) {
    return Object.keys(errors).reduce((result: string, key) => {
      const value = errors[key]
      if (typeof value === 'string' && key !== 'codigo' && key !== 'code') {
        return value
      }
      if (Array.isArray(value)) {
        return value[0]
      }
      return result
    }, '')
  }
  return ''
}

export const verifyLimits = (value: number, max: number, min: number) => {
  const maxValue = roundCurrencyValue(max, true)
  const minValue = roundCurrencyValue(min, false)

  if (value >= maxValue) {
    return maxValue
  }

  if (value <= minValue) {
    return minValue
  }

  return value
}

export const calcSimulateInsurer = (
  value: number,
  factor: IFactor,
  isParcel?: boolean,
  insurer?: IInsurer
) => {
  if (!insurer) {
    return 0
  }

  // Seguro Financiado

  // Seguro Financiado por valor solicitado

  if (insurer.tipo_seguro === InsurerType.FINANCIADO && factor.seguro_porcentagem && !isParcel) {
    const calculatedInsurer = value * (factor.seguro / 100)
    const calculatedInstallmentWithInsurer =
      (value +
        calculatedInsurer +
        (factor.tarifa_porcentagem
          ? value * (factor.valor_efetivo_tarifas / 100)
          : factor.valor_efetivo_tarifas)) /
      factor.fator
    return calculatedInstallmentWithInsurer // Formula 1 - OK

    // Mudança de calculo feita 1 vez requisitada pela tarefa CEP-7258
    // Mudança de calculo feita 1 vez requisitada pela tarefa CEP-7339
    // Mudança de calculo feita no dia 02/05/2024 - em call com Amilcar, Walrick, Lucas e Damaso - OK
  }

  if (insurer.tipo_seguro === InsurerType.FINANCIADO && !factor.seguro_porcentagem && !isParcel) {
    if (factor.tarifa_porcentagem) {
      // Novo valor para a tarifa porcentagemm
      const newValueTaxe = value * (factor.valor_efetivo_tarifas / 100)
      const newValue = (value + factor.seguro + newValueTaxe) / factor.fator
      return newValue
    }
    return (value + factor.seguro + factor.valor_efetivo_tarifas) / factor.fator // Formula 4 - OK
  }

  // Seguro Parcela

  // Seguro parcela com valor solicitado

  if (insurer.tipo_seguro === InsurerType.PARCELA && factor.seguro_porcentagem && !isParcel) {
    if (factor.tarifa_porcentagem) {
      const taxePercent = factor.valor_efetivo_tarifas / 100
      const taxeValue = value * taxePercent
      const valueInsurer = ((taxeValue + value) / factor.fator) * (factor.seguro / 100)
      return valueInsurer + (taxeValue + value) / factor.fator
    }
    const calculatedParcel = (value + factor.valor_efetivo_tarifas) / factor.fator
    const calculatedInsurer = calculatedParcel * (factor.seguro / 100)
    return calculatedParcel + calculatedInsurer // Formula 7 - OK
  }

  if (insurer.tipo_seguro === InsurerType.PARCELA && !factor.seguro_porcentagem && !isParcel) {
    const calculatedParcel = (value + factor.valor_efetivo_tarifas) / factor.fator
    if (factor.tarifa_porcentagem) {
      const taxePercent = factor.valor_efetivo_tarifas / 100
      const taxeValue = value * taxePercent
      return (value + taxeValue) / factor.fator + factor.seguro
    }
    return calculatedParcel + factor.seguro // Formula 8 - OK
  }

  // Seguro parcela com valor por parcela

  if (insurer.tipo_seguro === InsurerType.PARCELA && factor.seguro_porcentagem && isParcel) {
    const calculatedInsurer = value * (factor.seguro / 100)
    return value + calculatedInsurer // Formula 9 - OK

    // Novo calculo mediante a solicitação na task CEP-7258
    // Novo calculo mediante a solicitação na task CEP-7339
    // return factor.fator * (value - factor.seguro) - factor.tarifas
  }

  if (insurer.tipo_seguro === InsurerType.PARCELA && !factor.seguro_porcentagem && isParcel) {
    return value + factor.seguro // Formula 10 - OK

    // Novo calculo mediante a solicitação na task CEP-7258
    // Novo calculo mediante a solicitação na task CEP-7339
  }

  // Solicitado na task CEP 7258

  if (insurer.tipo_seguro === InsurerType.FINANCIADO && isParcel) {
    return value * factor.fator // Formula 11 - OK
  }

  return 0
}

export const calcSimulate = ({
  values,
  factor,
  isParcel,
  insurer,
  isSimularPost,
  enabledSecurity,
}: ICalcSimulate) => {
  const newInsurerValue = calcSimulateInsurer(values, factor, isParcel, insurer)
  const isInsurerFinancial = insurer?.tipo_seguro === InsurerType.FINANCIADO
  const isTaxeInPercent = factor.tarifa_porcentagem
  const isInsurerInPercent = factor.seguro_porcentagem

  if (isSimularPost) {
    if (isInsurerFinancial) {
      const newValueWithoutInsurer = factor.recebivel - factor.seguro / factor.prazo
      return {
        newValue: roundCurrencyValue(isParcel ? factor.valor_liberado : newValueWithoutInsurer), // Formula para valor solicitado/parcela de seguro do tipo financiado
        newInsurerValue: isParcel ? 0 : factor.recebivel,
      }
    }
    return {
      newValue: roundCurrencyValue(isParcel ? factor.valor_liberado : factor.recebivel), // Formula para valor solicitado/parcela de seguro do tipo parcela
      newInsurerValue: factor.recebivel + factor.seguro,
    }
  }

  if (isParcel) {
    let newValue
    if (isInsurerFinancial) {
      const newTaxe = isTaxeInPercent
        ? factor.valor_efetivo_tarifas / 100
        : factor.valor_efetivo_tarifas
      const newInsurer = enabledSecurity
        ? isInsurerInPercent
          ? factor.seguro / 100
          : factor.seguro
        : 0
      const releasedValueNew = roundCurrencyValue(values * factor.fator)

      if (isTaxeInPercent && !isInsurerInPercent) {
        newValue = (releasedValueNew - newInsurer) / (1 + newTaxe)
      } else if (isTaxeInPercent && isInsurerInPercent) {
        newValue = releasedValueNew / (1 + newTaxe + newInsurer)
      } else if (!isTaxeInPercent && isInsurerInPercent) {
        newValue = (releasedValueNew - newTaxe) / (1 + newInsurer)
      } else {
        newValue = releasedValueNew - newTaxe - newInsurer
      }
    } else {
      if (isTaxeInPercent) {
        const newValueWithTaxes = (values * factor.fator) / (1 + factor.valor_efetivo_tarifas / 100)
        newValue = roundCurrencyValue(newValueWithTaxes) // Fórmula padrão
      } else {
        newValue = roundCurrencyValue(values * factor.fator) - factor.valor_efetivo_tarifas // Fórmula padrão
      }
    }

    return {
      newValue,
      newInsurerValue,
    }
  }

  // Calculos para valor solicitado
  if (!isParcel) {
    // Calculo para seguro financiado - Tarifa percentual - Seguro porcentagem
    if (isInsurerFinancial && isTaxeInPercent) {
      const taxePercent = factor.valor_efetivo_tarifas / 100
      return {
        newValue: roundCurrencyValue(values * taxePercent + values) / factor.fator, // Formula 1
        newInsurerValue,
      }
    }

    if (!isInsurerFinancial && isTaxeInPercent) {
      const taxePercent = factor.valor_efetivo_tarifas / 100
      const taxeValue = values * taxePercent
      return {
        newValue: roundCurrencyValue(values + taxeValue) / factor.fator, // Formula 1
        newInsurerValue,
      }
    }
  }

  return {
    newValue: roundCurrencyValue((values + factor.valor_efetivo_tarifas) / factor.fator), // Formula 1
    newInsurerValue,
  }
}

export const roundCurrencyValue = (num: number, isFloor: boolean = false) => {
  if (isFloor) {
    return Math.floor(num * 100) / 100
  }
  return Math.round(num * 100) / 100
}

export const isModality = (entity: IEntity) => {
  if (entity) {
    if (entity.module?.subModule === 'CP') return 'CP'
    if (entity.module?.subModule === 'CDC') return 'CDC'
    if (entity.module?.subModule === 'CDCVEICULO') return 'CDCVEICULO'
    if (entity.module?.subModule === 'PJ') return 'PJ'
    if (entity.module?.subModule === 'SUPERVISOR') return 'SUPERVISOR'
    if (entity.id === 'corban') return 'CORBAN'
  }
  return 'CEP'
}

export const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const getItem = (key: string): string => {
  return localStorage.getItem(key) || ''
}

export const removeItem = (key: string): void => {
  localStorage.removeItem(key)
}

export const formatCurrencyNumber = (value: number) => {
  return (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
}
export const showInformationsAboutLot = (item: IReceivable, dontPay?: boolean) => {
  if (
    item.lote &&
    item.lote.status !== 'conciliado' &&
    item.lote.status !== 'rascunho' &&
    item.lote.status !== 'conciliado-automaticamente' &&
    item.pagamentos?.length === 0 &&
    dontPay
  ) {
    return true
  }
  return false
}

export const typeNormalizer = (value: string) => {
  if (value === 'cpf') return cpfNormalizer
  if (value === 'cnpj') return cnpjNormalizer
  if (value === 'telefone_celular') return phoneNormalizer
  if (value === 'email') return maxLength(50)
  return maxLength(32)
}

export const typeValidate = (value: string) => {
  if (value === 'cpf') return cpfValidator
  if (value === 'cnpj') return cnpjValidator
}

export const removeDuplicated = (array: any[]) => {
  const ids = new Set()
  const result = []

  for (const item of array) {
    if (!ids.has(item.id)) {
      result.push(item)
      ids.add(item.id)
    }
  }

  return result
}

export const removeDuplicatedCompanies = (array: any[]) => {
  const ids = new Set()
  const result = []

  for (const item of array) {
    if (!ids.has(item.entidade.id)) {
      result.push(item)
      ids.add(item.entidade.id)
    }
  }

  return result
}

export const testAfterNormalization = (value: string, isCompany: boolean) => {
  if (isCompany) return regexCellPhoneCompany.test(phoneNormalizer(value))
  return regexCellPhoneUser.test(phoneNormalizer(value))
}

function aesDecifrado(ciphertext: Buffer, chave: Buffer, iv: Buffer) {
  const decifrar = createDecipheriv('aes-128-cbc', chave, iv)
  const decifrado = Buffer.concat([decifrar.update(ciphertext), decifrar.final()])

  return decifrado.toString('utf-8')
}

export function decodificarAndDecifrar(dadoCodificado: string) {
  // Chaves e IV
  const chave = Buffer.from('d3C1fr4B4ix0n3NG', 'utf-8')
  const iv = Buffer.from('j0mb4SV3t0res123', 'utf-8')
  // Decodificar Base64
  const dadoDecodificado = Buffer.from(dadoCodificado, 'base64')
  // Descriptografar AES-128
  const dadoDecifrado = aesDecifrado(dadoDecodificado, chave, iv)
  // Retornar os 5 primeiros caracteres
  return dadoDecifrado.substring(0, 5)
}

export const filteredBanksByEnv = () => {
  const isSer = ['serfinancas', 'serfinance'].some((keyword) =>
    process.env.REACT_APP_CREDITOR_BASE_URL?.includes(keyword)
  )
  if (!isSer) return banks
  const filteredBanks = banks.filter((bank) => bank.value === '530' || bank.value === '566')
  return filteredBanks
}
